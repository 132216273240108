.image_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 50px;
  height: calc(100vh - 282px);
  width: 80vw;

  @media (max-width: 650px) {
    margin-block-start: 0;
    height: auto;
  }
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
