.qr_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  max-width: 100%;
}

.qr_image {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
}
