.text_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 65vw;
}

.title {
  font-size: 50px;
  font-weight: bold;
  color: var(--main-text-color);
  text-align: center;
}

.text {
  font-size: 35px;
  font-weight: normal;
  color: var(--active-text-color);
  text-align: center;
}
