.logo_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100px;
  text-decoration: none;

  @media (max-width: 650px) {
    gap: 15px;
    flex-direction: column;
  }
}

.logo {
  width: 30px;
  height: auto;

  @media (max-width: 650px) {
    width: 30px;
  }
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: hsl(0, 0%, 30%);
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0.3em;

  @media (max-width: 650px) {
    font-size: 15px;
  }
}

.text:hover {
  color: var(--main-text-color);
}
