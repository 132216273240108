.section {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;

  @media (max-width: 650px) {
    width: 100%;
    align-items: center;
  }
}

.title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.link_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.text {
  font-size: 15px;
}

.link {
  text-decoration: none;
  font-size: 15px;
  color: hsl(0, 0%, 30%);
}

.link:hover {
  color: var(--main-text-color);
}
