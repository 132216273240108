.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu {
  box-sizing: border-box;
  border-block-end: 1.5px solid hsl(0, 0%, 95%);
}
