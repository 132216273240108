.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  height: auto;
  min-height: calc(100vh - 312px);
  margin-block: 50px;
  gap: 50px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.text_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  gap: 20px;
  width: 100%;
}

.text {
  text-align: center;
  font-size: 15px;
}

.bold {
  font-weight: bold;
}

.image {
  width: 100%;
  height: auto;
}
