.footer_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.footer_text {
  color: var(--main-text-color);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  text-align: center;

  @media (max-width: 650px) {
    font-size: 12px;
  }
}
