.icon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  opacity: 0.3;
  font-size: 40px;

  @media (max-width: 650px) {
    font-size: 40px;
    top: 50px;
  }
}

.icon:hover {
  cursor: pointer;
  opacity: 0.6;
}
