.nav_bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 30px;

  @media (max-width: 650px) {
    margin-block: 7px;
  }
}

.link_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  list-style-type: none;

  @media (max-width: 650px) {
    text-align: center;
    gap: 20px;
  }
}

.link {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: var(--main-text-color);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;

  @media (max-width: 650px) {
    font-size: 14px;
  }
}

.link:hover {
  color: hsl(0, 0%, 30%);
  font-weight: bold;
}

.link_active {
  color: hsl(0, 0%, 30%);
  font-weight: bold;
}
