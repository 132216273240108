:root {
  --basic-padding: 50px;
  --main-text-color: hsl(0, 0%, 60%);
  --active-text-color: hsl(0, 0%, 30%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--active-text-color);
  font-size: 15px;
}
