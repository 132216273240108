.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 80vw;
  min-height: 100vh;
  color: var(--main-text-color);
  font-family: Arial, Helvetica, sans-serif;
}
