.nav_bar {
  border-block-start: 1.5px solid hsl(0, 0%, 95%);
}

.wrapper {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 393px);
  margin-block: 50px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  grid-gap: 50px;
}
