.section {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 65%;
  height: auto;
  min-height: calc(100vh - 312px);
  margin-block: 50px;
  gap: 50px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.text_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.section_title {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  margin-block-end: 10px;
}

.text_year {
  font-weight: bold;
  font-size: 15px;
  margin-block-start: 10px;
}

.text {
  font-size: 15px;
}
