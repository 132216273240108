.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  height: auto;
  min-height: calc(100vh - 440px);
  margin-block: 50px;
  gap: 50px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.text_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: auto;
  gap: 30px;
  width: 100%;
}

.text {
  line-height: 1.5;
  font-size: 15px;
}

.bold {
  font-weight: bold;
}

.image {
  width: 100%;
  height: auto;
}
