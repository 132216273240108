.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 887;
  margin: auto;
  background-color: hsla(0, 0%, 100%, 0.95);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  border: none;
  background-color: transparent;
  z-index: 888;
}

.button:hover {
  cursor: pointer;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
  width: 95%;
}
